<template>
    <div class="table-responsive">
        <table
            id="variables-datatable"
            class="table table-centered table-striped table-bordered my-2"
        >
            <thead>
                <tr>
                    <th>Code</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="variable in variables" :key="variable.code">
                    <td>{{ variable.code }}</td>
                    <td>{{ variable.description }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        variables: {
            type: Array,
            required: false,
            default: () => []
        }
    }
};
</script>
