<template>
    <layout>
        <were-changes-alert :were-changes="wereChanges" @save="save" />
        <page-header :items="items" :title="title" />
        <div v-if="template.id" class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex flex-wrap">
                            <div class="form-group col-12">
                                <b-form-checkbox
                                    v-model="template.klaviyo_enabled"
                                    switch
                                >
                                    {{
                                        !template.klaviyo_enabled
                                            ? 'Enable'
                                            : 'Disable'
                                    }}
                                    sending email via Klaviyo
                                </b-form-checkbox>
                            </div>
                            <template v-if="!template.klaviyo_enabled">
                                <div
                                    class="form-group col-12 col-md-6 col-lg-3"
                                >
                                    <label for="templateCode">Code</label>
                                    <input
                                        id="templateCode"
                                        ref="name"
                                        class="form-control"
                                        disabled
                                        type="text"
                                        :value="template.code"
                                    />
                                </div>
                                <div
                                    class="form-group col-12 col-md-6 col-lg-3"
                                >
                                    <label for="templateName">Name</label>
                                    <input
                                        id="templateName"
                                        ref="name"
                                        class="form-control"
                                        disabled
                                        type="text"
                                        :value="template.name"
                                    />
                                </div>
                                <div
                                    class="form-group col-12 col-md-12 col-lg-6"
                                >
                                    <label for="templateDescription">
                                        Description
                                    </label>
                                    <input
                                        id="templateDescription"
                                        ref="description"
                                        class="form-control"
                                        disabled
                                        type="text"
                                        :value="template.description"
                                    />
                                </div>
                                <div
                                    v-if="variables.length"
                                    class="form-group col-12 col-lg-6"
                                >
                                    <button
                                        class="btn btn-info waves-effect waves-light mr-1"
                                        @click="
                                            variablesTable = !variablesTable
                                        "
                                    >
                                        {{ variableTableButtonText }}
                                    </button>
                                    <variables-table
                                        v-if="variablesTable"
                                        :variables="variables"
                                    ></variables-table>
                                </div>
                                <div class="form-group col-12">
                                    <label for="templateTitle">Title</label>
                                    <input
                                        id="templateTitle"
                                        v-model="template.title"
                                        class="form-control"
                                        type="text"
                                    />
                                </div>
                                <div class="form-group col-12">
                                    <label for="templateContent">Content</label>
                                    <wysiwyg-editor
                                        v-model="template.content"
                                        :email-mode="true"
                                    />
                                </div>
                            </template>
                            <div class="col-12 d-flex justify-content-end">
                                <button
                                    class="btn btn-success waves-effect waves-light"
                                    type="button"
                                    @click="save"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import VariablesTable from './VariablesTable';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    page: {
        title: 'Mail Template Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        WysiwygEditor,
        VariablesTable,
        WereChangesAlert
    },

    data() {
        return {
            title: 'Mail Template Details',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Mailing',
                    to: '/mailing'
                },
                {
                    text: 'Mail template details',
                    active: true
                }
            ],
            template: {},
            variablesTable: false,
            variables: [],
            initValueStringify: ''
        };
    },

    computed: {
        variableTableButtonText() {
            return !this.variablesTable
                ? 'Show available variables'
                : 'Hide available variables';
        },

        wereChanges() {
            return JSON.stringify(this.template) !== this.initValueStringify;
        }
    },

    async mounted() {
        try {
            const id = this.$route.params.id;

            this.template = await this.getMailTemplate(id);

            this.variables = await this.getVariables(this.template.code);

            this.initValueStringify = JSON.stringify(this.template);
        } catch (err) {
            console.error(err);

            if (err.response.status === 404) {
                return this.$router.push('/mailing');
            }

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getMailTemplate: 'mailTemplates/show',
            updateMailTemplate: 'mailTemplates/update',
            getVariables: 'mailTemplates/getVariables'
        }),

        async save() {
            try {
                this.updateMailTemplate(this.template);

                this.initValueStringify = JSON.stringify(this.template);

                this.$toaster('Template has been updated!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>
